import React from "react";
import './Footer.css';
const Footer = () => {

  return(
    <>
    <head>
    <meta charset="UTF-8"></meta>
      <meta charset="UTF-8"></meta>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
    </head>
    <body>
        <footer class="footer">
            <div class="footer-content">
                <h3>MC Peinture</h3>
                <p>Tout droits réservées à l'artiste. Pour tous renseignements :</p>
                <p>Adresse mail : mcpeinture11@gmail.com</p>
                <ul class="socials">
                    <li><a href="https://www.pinterest.fr/alexandrecollot2/_created/"><i class="fa fa-pinterest"></i></a></li>
                    <li><a href="https://www.instagram.com/mcpeintures12/"><i class="fa fa-instagram"></i></a></li>
                </ul>
            </div>
            <div class="footer-bottom">
                <p>copyright &copy; <a href="#">MC Peinture</a>  </p>
                        <div class="footer-menu">
                          <ul class="f-menu">
                            <li><a href="#anchorWelcome">Présentation</a></li>
                            <li><a href="#anchorGallery">Oeuvres</a></li>
                          </ul>
                        </div>
            </div>
        </footer>
    </body>
    </>
  );
  }
export default Footer;