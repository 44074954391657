import React, { useState, useEffect } from "react";
import "./Mosaic.css";
import data from "../../assets/images.json";
import MosaicImage from "./MosaicImage.js";
import Modal from "./Modal.js";
import StackGrid from "react-stack-grid";
const itemsByPage = 15;

const Mosaic = () => {
  const [clickedImg, setClickedImg] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [indexOfFirstTodo, setIndexOfFirstTodo] = useState(0);
  const [indexOfLastTodo, setIndexOfLastTodo] = useState(itemsByPage);
  const [currentPage, setCurrentPage] = useState(1);
  const [shouldLoadImage, setShouldLoadImage] = useState(false);

  const handleClick = (item, index) => {
    document.querySelector("body").style.overflow = "hidden";
    document.getElementById("divHeader").style.visibility = "hidden";

    setCurrentIndex(index);
    setClickedImg(item.link);
  };

  const handelRotationRight = () => {
    const totalLength = data.data.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = data.data[0].link;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = data.data.filter((item) => {
      return data.data.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].link;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  const handelRotationLeft = () => {
    const totalLength = data.data.length;
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      const newUrl = data.data[totalLength - 1].link;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = data.data.filter((item) => {
      return data.data.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].link;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  // const [renderHook, setRenderHook] = useState();
  // useEffect(() => {
  //     setTimeout(() => {
  //       setRenderHook(null);
  //     }, 0);
  //   }, []);
  return (

    <>
      <span className="anchorHidden" id="anchorGallery" />
      <div id="divLabelGallerie">
        <label>GALERIE</label>
      </div>
      <div id="divMos">
        <StackGrid monitorImagesLoaded={true} onLayout={()=>setShouldLoadImage(true)} columnWidth={500} >
          {data.data.map((item, index) => (
            <div key={index} style={{width:500,height:item.height}} onClick={() => handleClick(item, index)}>
              {/* <MosaicImage item={item} shouldLoad={shouldLoadImage}/> */}
              {/* <img
                  src={item.link}
                  alt={item.text}
                  className="MosaicImage"
                  loading="lazy"

                />  */}
              <picture>
                <source srcSet={item.avif} type="octet-stream" />
                <img
                  src={item.avif}
                  alt={item.text}
                  className="MosaicImage"
                  loading="lazy"
                  onClick={() => handleClick(item, index)}
                />
              </picture>
            </div>
          ))}
        </StackGrid>
      </div>
      <div id="divWrapper">
        <div id="divMosaic">
          {data.data.map((item, index) => (
            <div key={index} style={{width:500,height:item.height}} className={"divTableau"}>
              <picture>
                <source srcSet={item.avif} type="octet-stream" />
                <img
                  src={item.avif}
                  alt={item.text}
                  style={{width:500,height:item.height}}
                  className="MosaicImage"
                  loading="lazy"
                  onClick={() => handleClick(item, index)}
                />
              </picture>
            </div>
          ))}
        </div>
      </div>
      <div id="divModal">
        {clickedImg && (
          <Modal
            clickedImg={clickedImg}
            handelRotationRight={handelRotationRight}
            setClickedImg={setClickedImg}
            handelRotationLeft={handelRotationLeft}
          />
        )}
      </div>

      {/* <div id="divWrapper">
        <div id="divMosaic">
          {data.data.map((item, index) => (
            <div key={index} className={"divTableau"}>
              <picture>
                <source srcSet={item.avif} type="octet-stream" />
                <img
                  src={item.link}
                  alt={item.text}
                  className="MosaicImage"
                  onClick={() => handleClick(item, index)}
                />
              </picture>
            </div>
          ))}
        </div>
        <div>
          {clickedImg && (
            <Modal
              clickedImg={clickedImg}
              handelRotationRight={handelRotationRight}
              setClickedImg={setClickedImg}
              handelRotationLeft={handelRotationLeft}
            />
          )}
        </div>
      </div> */}
    </>
  );
};
export default Mosaic;
