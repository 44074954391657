import React from "react";
import './About.css';
const About = () => {

  function reveal() {
    var reveals = document.querySelectorAll(".reveal");
  
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;
  
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }
  
  window.addEventListener("load", reveal);
  return(
    <>
    <section id="idAbout">
      <p id="idDescription" className="reveal">
        Bienvenue sur MCPeinture ! Vous retrouverez sur ce site les oeuvres de Monique Collot. <br/>
        À travers son oeuvre, elle a voulu dépeindre sa vision du monde, de son quotidien et
        de sa condition de femme.
        Allant du simple autoportrait jusqu'à la retranscription de sa vision de Dieu, son art aime 
        a évoquer la vie dans toutes ses nuances.
        Son amour des paysages français ornés de leurs cathédrales ou des campagnes asiatiques mirifiées, elle dépeint aussi sa vision du paradis et de l'enfer avec une vision unique.  <br/>
        Produit dans les années 1990, cette collection de tableaux est son oeuvre exclusive et limitée.
        <br></br>
        <br></br>
        Suivez-la sur <b>instagram <a href="https://www.instagram.com/mcpeintures12/"><i class="fa fa-instagram"></i></a></b> ou pinterest <b><a href="https://www.pinterest.fr/alexandrecollot2/_created/"><i class="fa fa-pinterest"></i></a></b>!
        <p>Pour nous contacter : mcpeinture11@gmail.com</p>
      </p>
    </section>
    </>
  );
  }
export default About;