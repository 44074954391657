
import './App.css';
import Logo from './assets/MCPeintureLogo.png'
import Navbar from './components/Navbar/Navbar';
import About from './components/About/About';
import Mosaic from './components/Mosaique/Mosaic';
import Footer from './components/Footer/Footer'

function App() {
  return (
    <div className="App">
      <span className="anchorHidden" id="anchorWelcome" />
      <Navbar/>
      <div>
        <img id="imgBigLogo" src={Logo} alt='logo MCPeinture'/>
      </div>
      <About/>
      <Mosaic/>
      <Footer/>
    </div>

  );
}

export default App;
